// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getFirestore, doc, getDoc } from 'firebase/firestore'
// import { getStorage, ref, uploadBytes } from 'firebase/storage'

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyD15E6FnBulMSGu5-EuCsRyTzRegeuTxQA',
  authDomain: 'tremblant-infra.firebaseapp.com',
  projectId: 'tremblant-infra',
  storageBucket: 'tremblant-infra.appspot.com',
  messagingSenderId: '1078045170507',
  appId: '1:1078045170507:web:52a0688b71b3b96a2166ce',
  measurementId: 'G-L40GPVJG1B',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const db = getFirestore(app)
// const storage = getStorage()
// const analytics = getAnalytics(app)

// Get a document by ID
async function getDocById(collection = 'market', id = 'changeUSD') {
  const docRef = doc(db, collection, id)
  const docSnap = await getDoc(docRef)

  if (docSnap.exists()) {
    // console.log("Document data:", docSnap.data())
    return docSnap.data()
  }

  // console.log("No such document!", null)
  return null
}

export default getDocById
